import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="Owrs" />
    <h1>Owrs</h1>
    <p>
      SSL-sertifikaait kuntoon? Tarvitsetko pientä webpalvelua? Nopeat
      kotisivut? Vauhdikas verkkokauppa? Jotain pientä mobiilille?
    </p>
    <p>
      Asioitanne hoitaa luotettava webdevaaja, jolla on kokemusta
      ohjelmistokehityksessä työpaikkojen muodossa mm. startupeista ja
      vakiintuneistakin firmoista.
    </p>
    <p>
      Minulle tärkeitä asioita joista en tingi ovat laatu, tietoturva ja
      suorituskyky. Nykyisin saa nähdä aivan liian paljon hitaita,
      huonolaatuisia, soveltumattomilla teknologioilla toteutettuja ja vieläpä
      tietoturvatottomia palveluja.
    </p>
    <p>
      Haluatko, että ongelmasi ratkaistaan asianmukaisella tavalla, järkevällä
      teknologialla, läpinäkyvällä prosessin hoitamisella ja pienen kynnyksen
      kontaktilla kehittäjään?
    </p>
    <p>
      Työkaluni tarpeesta riippuen: Node/JS/TS(Express, Koa, Strapi ),
      React(Gatsby, NextJs, CRA, React Native), Vue, Webpack, Php(Laravel,
      Magento), Python(Flask, skriptit), Docker, Git, Linux(oli tarve tai ei)
      tai ihan vaan bash skriptit
    </p>
    <p>
      Kiinnostuitko? Otahan <Link to="contact">yhteyttä</Link> ongelmasi kanssa,
      niin katsotaan mitäs saatais aikaseksi.
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default AboutPage
